import React from "react";
import Layout from "../components/layout/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="flex w-full justify-items-center">
      <div className="relative flex items-center justify-center">
        <img
          className="animate-pulse absolute inline-flex"
          src="/img/not_found.png"
          alt="mr stock bot title image"
        />
        <img
          className="relative inline-flex"
          src="/img/not_found.png"
          alt="mr stock bot title image"
        />
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
